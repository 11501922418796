import type { JSX } from 'react';
import classnames from 'classnames';

import ExitIcon from '../../../../assets/icons/player-exit.svg';
import { Clickable } from '../../../clickable/Clickable';

import styles from '../ControlButton.css';

interface IProps {
  onClick: () => void;
  ariaLabel: string;
}

const ExitControlButton = ({ onClick, ariaLabel }: IProps): JSX.Element => {
  return (
    <Clickable
      customStyle={classnames(styles.control, styles.exitControl)}
      onClick={onClick}
      ariaLabel={ariaLabel}
    >
      <ExitIcon />
    </Clickable>
  );
};

export { ExitControlButton };
