/**
 * Creates a throttled function that only invokes func at most once per every wait milliseconds.
 * @param fn a function to be throttled
 * @param timer throttle duration in ms
 * @returns a function throttled
 */
function throttle(
  fn: (...args: unknown[]) => void,
  timer = 0,
): (...args: unknown[]) => void {
  if (typeof fn !== 'function') {
    throw new Error('Providing a function as first parameter is mandatory!');
  }

  let lastRefreshTime = performance.now();
  let isHasAlreadyBeenCalledOnce = false;

  return (...args): void => {
    if (
      !isHasAlreadyBeenCalledOnce ||
      performance.now() - lastRefreshTime >= timer
    ) {
      isHasAlreadyBeenCalledOnce = true;
      lastRefreshTime = performance.now();
      fn(...args);
    }
  };
}

export default throttle;
