import { type JSX, useCallback, useContext } from 'react';
import {
  ButtonIcon,
  PlayerSoundOffIcon,
  PlayerSoundOnIcon,
} from '@dce-front/dive';

import { MinimalPlayerContext } from '../../../../context';
import { useAriaLabels, useMinimalPlayerIsMuted } from '../../../../hooks';
import { Clickable } from '../../../clickable/Clickable';
import { CONTROL_BUTTON_VARIANTS, TControlButtonVariants } from '../types';

import styles from '../ControlButton.css';

interface IDefaultProps {
  variant?: TControlButtonVariants;
}

export interface IProps extends IDefaultProps {
  onMute?: () => void | undefined;
  onUnmute?: () => void | undefined;
}

const SoundControlButton = ({
  variant = CONTROL_BUTTON_VARIANTS.DEFAULT,
  onMute,
  onUnmute,
}: IProps): JSX.Element | null => {
  const { minimalPlayer } = useContext(MinimalPlayerContext);
  const ariaLabels = useAriaLabels(minimalPlayer, ['Mute', 'Unmute']);
  const isMuted = useMinimalPlayerIsMuted(minimalPlayer);

  const onClick = useCallback(() => {
    if (minimalPlayer.player.isMute()) {
      onUnmute?.();
      minimalPlayer.unMute();
    } else {
      onMute?.();
      minimalPlayer.mute();
    }
  }, [minimalPlayer, onMute, onUnmute]);

  if (!minimalPlayer) {
    return null;
  }

  switch (variant) {
    case CONTROL_BUTTON_VARIANTS.VIDEO:
      // Let the client import the css from dive if they want to consume this variant
      return (
        <ButtonIcon
          variant={CONTROL_BUTTON_VARIANTS.VIDEO}
          aria-label={isMuted ? ariaLabels.Unmute : ariaLabels.Mute}
          icon={isMuted ? <PlayerSoundOffIcon /> : <PlayerSoundOnIcon />}
          onClick={onClick}
          data-testid="soundControl"
        />
      );
    case CONTROL_BUTTON_VARIANTS.DEFAULT:
    default:
      return (
        <Clickable
          customStyle={styles.control}
          onClick={onClick}
          ariaLabel={isMuted ? ariaLabels.Unmute : ariaLabels.Mute}
          data-testid="soundControl"
        >
          {isMuted ? <PlayerSoundOffIcon /> : <PlayerSoundOnIcon />}
        </Clickable>
      );
  }
};

export { SoundControlButton };
