import { type JSX } from 'react';
import {
  ButtonIcon,
  PlayerFullScreenIcon,
  PlayerFullScreenOffIcon,
} from '@dce-front/dive';

import { Clickable } from '../../../clickable/Clickable';
import { CONTROL_BUTTON_VARIANTS, TControlButtonVariants } from '../types';

import styles from '../ControlButton.css';

interface IDefaultProps {
  variant?: TControlButtonVariants;
}

export interface IProps extends IDefaultProps {
  isFullFrame: boolean;
  onClick: () => void;
  ariaLabel: string;
}

const FullFrameControlButton = ({
  variant = CONTROL_BUTTON_VARIANTS.DEFAULT,
  isFullFrame,
  onClick,
  ariaLabel,
}: IProps): JSX.Element => {
  switch (variant) {
    case CONTROL_BUTTON_VARIANTS.VIDEO:
      // Let the client import the css from dive if they want to consume this variant
      return (
        <ButtonIcon
          variant={CONTROL_BUTTON_VARIANTS.VIDEO}
          aria-label={ariaLabel}
          icon={
            isFullFrame ? <PlayerFullScreenOffIcon /> : <PlayerFullScreenIcon />
          }
          onClick={onClick}
          data-testid="fullFrameControl"
        />
      );
    case CONTROL_BUTTON_VARIANTS.DEFAULT:
    default:
      return (
        <Clickable
          customStyle={styles.control}
          onClick={onClick}
          ariaLabel={ariaLabel}
          data-testid="fullFrameControl"
        >
          {isFullFrame ? <PlayerFullScreenOffIcon /> : <PlayerFullScreenIcon />}
        </Clickable>
      );
  }
};

export { FullFrameControlButton };
