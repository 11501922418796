import type { JSX } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';

interface IMinimalPlayerContext {
  minimalPlayer: any;
}

const defaultMinimalPlayerContext: IMinimalPlayerContext = {
  minimalPlayer: null,
};

const MinimalPlayerContext = createContext(defaultMinimalPlayerContext);

const MinimalPlayerContextProvider = ({
  minimalPlayer,
  children,
}: React.PropsWithChildren<IMinimalPlayerContext>): JSX.Element => {
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  useEffect(() => {
    setIsPlayerReady(minimalPlayer?.ready);
  }, [minimalPlayer?.ready]);

  const value = useMemo(() => {
    return {
      minimalPlayer: isPlayerReady ? minimalPlayer : null,
    };
  }, [minimalPlayer, isPlayerReady]);

  return (
    <MinimalPlayerContext.Provider value={value}>
      {children}
    </MinimalPlayerContext.Provider>
  );
};

export {
  IMinimalPlayerContext,
  MinimalPlayerContext,
  MinimalPlayerContextProvider,
};
