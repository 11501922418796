import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BusinessTypes } from '@canalplus/oneplayer-constants';

import { ActionCallbacks } from '../components/miniprogressbar/progressBarUtils';
import { useMinimalPlayerIsPlaying } from './useMinimalPlayerIsPlaying';

const {
  SEEK_ARROW_DURATION: { SEEK_MINUS_FIXED_DURATION, SEEK_PLUS_FIXED_DURATION },
} = BusinessTypes;

interface Props {
  minimalPlayer: any;
  onKey: (event: KeyboardEvent, callbacks: ActionCallbacks) => void;
  onSeek?: () => void | undefined;
  onPlay?: () => void | undefined;
  onPause?: () => void | undefined;
}

interface TMiniProgressBar {
  duration: number | null;
  currentPosition: number | null;
  onMouseUp: () => void;
  onMouseDown: () => void;
  onDrag: (ev: React.FormEvent<HTMLInputElement>) => void;
}

/**
 *
 *  Custom hook handling minimal player logic.
 *  this hooks adds event listener to handle key navigation and position update.
 * @param arg0 custom hooks props
 * @param arg0.onSeek callback to execute when seeking is requested
 * @param arg0.onPlay callback to execute when play is requested
 * @param arg0.onPause callback to execute when pause is requested
 * @param arg0.onKey callack to execute when a key is pressed
 * @param arg0.minimalPlayer minimal player instance
 * @returns the current position and the duration of the content
 */
export default function useMiniProgressBar({
  minimalPlayer,
  onKey,
  onPause,
  onPlay,
  onSeek,
}: Props): TMiniProgressBar {
  const duration = useMemo(
    () => Math.round(minimalPlayer?.getVideoDuration()) || null,
    [minimalPlayer],
  );
  const [currentPosition, setCurrentPosition] = useState<number | null>(
    Math.round(minimalPlayer?.getPosition()) || null,
  );

  const isPlaying = useMinimalPlayerIsPlaying(minimalPlayer);

  const isKeyHoldingRef = useRef<boolean>(false);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent): void => {
      isKeyHoldingRef.current = true;

      if (currentPosition === null || duration === null) {
        return;
      }

      onKey(event, {
        backward: (): void =>
          setCurrentPosition(
            Math.max(currentPosition - SEEK_MINUS_FIXED_DURATION, 0),
          ),
        forward: (): void =>
          setCurrentPosition(
            Math.min(currentPosition + SEEK_PLUS_FIXED_DURATION, duration),
          ),
      });
    };

    const onKeyUp = (event: KeyboardEvent): void => {
      isKeyHoldingRef.current = false;

      if (currentPosition === null || duration === null) {
        return;
      }

      onKey(event, {
        backward: (): void => {
          onSeek?.();
          minimalPlayer.seekTo(currentPosition);
        },
        forward: (): void => {
          onSeek?.();
          minimalPlayer.seekTo(currentPosition);
        },
        playpause: (): void => {
          if (isPlaying) {
            onPause?.();
            minimalPlayer.pause();
          } else {
            onPlay?.();
            minimalPlayer.play();
          }
        },
      });
    };
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [
    minimalPlayer,
    duration,
    isPlaying,
    currentPosition,
    onSeek,
    onPlay,
    onPause,
    onKey,
  ]);

  useEffect(() => {
    const updatePosition = ({ position }: { position: number }): void => {
      if (!isKeyHoldingRef.current) {
        setCurrentPosition(Math.round(position));
      }
    };
    if (minimalPlayer) {
      minimalPlayer.addEventListener('positionUpdate', updatePosition);
    }
    return (): void => {
      minimalPlayer?.removeEventListener('positionUpdate', updatePosition);
    };
  }, [isKeyHoldingRef, minimalPlayer]);

  const onDrag = useCallback(
    (evt: React.FormEvent<HTMLInputElement>): void => {
      if (evt.currentTarget) {
        setCurrentPosition(parseInt(evt.currentTarget.value, 10));
      }
    },
    [setCurrentPosition],
  );

  const onMouseDown = useCallback((): void => {
    isKeyHoldingRef.current = true;
  }, []);

  const onMouseUp = useCallback((): void => {
    if (minimalPlayer) {
      isKeyHoldingRef.current = false;
      onSeek?.();
      minimalPlayer.seekTo(currentPosition);
    }
  }, [minimalPlayer, onSeek, currentPosition]);

  return { duration, currentPosition, onDrag, onMouseUp, onMouseDown };
}
