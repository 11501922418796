import type { JSX } from 'react';
import { useCallback, useContext, useEffect, useRef } from 'react';

import { BusinessTypes, KeyMapping } from '@canalplus/oneplayer-constants';
import { secToFormatHours } from '@canalplus/oneplayer-utils';

import { MinimalPlayerContext } from '../../context';
import { useAriaLabels } from '../../hooks';
import useMiniProgressBar from '../../hooks/useMiniProgressBar';
import { Slider } from '../slider/Slider';
import { createOnKeyHandler } from './progressBarUtils';

import styles from './WebMiniProgressBar.css';

const {
  WEB_KEY_MAPPING,
  KEYS: { LEFT, RIGHT, SPACE },
} = KeyMapping;

const {
  VARIANTS: { MYCANAL },
} = BusinessTypes;

export interface IProps {
  variant?: string;
  isFullFrame?: boolean;
  onSeek?: () => void | undefined;
}

/**
 *
 * @param arg0 WebMiniProgressBar props
 * @param arg0.variant the variant
 * @param arg0.isFullFrame is miniplayer in fullframe mode
 * @param arg0.onSeek callback to execute when 'seek' action is requested
 * @returns progress bar which is synchronised with the player used and allows the user to
 * navigate into the stream.
 */
const WebMiniProgressBar = ({
  variant = MYCANAL,
  isFullFrame,
  onSeek,
}: IProps): JSX.Element | null => {
  const { minimalPlayer } = useContext(MinimalPlayerContext);
  const ariaLabels = useAriaLabels(minimalPlayer, ['ProgressBar']);

  const sliderRef = useRef<HTMLInputElement | null>(null);

  const canUseKeys = useCallback(
    (e: KeyboardEvent): boolean => {
      return isFullFrame || e.target === sliderRef.current;
    },
    [isFullFrame],
  );

  const { duration, currentPosition, onDrag, onMouseDown, onMouseUp } =
    useMiniProgressBar({
      minimalPlayer,
      onKey: createOnKeyHandler(
        [
          { action: 'forward', keys: WEB_KEY_MAPPING[RIGHT] },
          { action: 'backward', keys: WEB_KEY_MAPPING[LEFT] },
          { action: 'playpause', keys: [WEB_KEY_MAPPING[SPACE]] },
        ],
        canUseKeys,
      ),
      onSeek,
    });

  const updateProgressBarStyle = useCallback(() => {
    if (duration !== null && currentPosition !== null && sliderRef.current) {
      const positionInPercentage = (currentPosition / duration) * 100;
      const actionColor = styles[`actionColor--${variant}`];
      const white = styles.colorWhite30;
      sliderRef.current.style.background = `linear-gradient(to right, ${actionColor} 0%, ${actionColor} ${positionInPercentage}%, ${white} ${positionInPercentage}%, ${white} 100%)`;
    }
  }, [duration, currentPosition, variant]);

  useEffect(() => {
    updateProgressBarStyle();
  }, [updateProgressBarStyle]);

  if (!minimalPlayer || duration === null || currentPosition === null) {
    return null;
  }

  return (
    <div className={styles.progressBarWrapper}>
      <div className={styles.position}>{secToFormatHours(currentPosition)}</div>
      <Slider
        ariaLabel={ariaLabels.ProgressBar}
        ref={sliderRef}
        minValue={0}
        maxValue={duration}
        value={currentPosition}
        onDrag={(ev): void => {
          onDrag(ev);
          updateProgressBarStyle();
        }}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        isTv={false}
      />
      <div className={styles.duration}>
        {secToFormatHours(duration - currentPosition)}
      </div>
    </div>
  );
};

export { WebMiniProgressBar };
