import type { JSX } from 'react';
import classnames from 'classnames';

import styles from './Clickable.css';

interface IProps {
  children: React.ReactNode;
  ariaLabel?: string;
  customStyle?: string;
  id?: string;
  isDisabled?: boolean;
  'data-testid'?: string;
  trackableAction?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
}

const Clickable = ({
  children,
  ariaLabel,
  customStyle,
  id,
  'data-testid': dataTestId,
  trackableAction,
  isDisabled = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: IProps): JSX.Element => {
  return (
    <button
      id={id}
      type="button"
      className={classnames(styles.clickable, customStyle)}
      onClick={!isDisabled ? onClick : undefined}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-label={ariaLabel}
      aria-disabled={isDisabled}
      data-testid={dataTestId}
      data-sp-action={trackableAction}
    >
      {children}
    </button>
  );
};

export { Clickable };
