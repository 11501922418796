export type Actions = 'forward' | 'backward' | 'playpause';

export type ActionCallbacks = Partial<{ [K in Actions]: () => void }>;

/**
 * Creates a key event handler based on a set of action
 * @param actions Array of action => keys map
 * @param isActive Callback to bypass some events
 * @returns a function taking a keyboard event and the action => callback map
 */
export const createOnKeyHandler = (
  actions: Array<{ action: Actions; keys: number[] }>,
  isActive?: (e: KeyboardEvent) => boolean,
): ((
  event: KeyboardEvent,
  callbacks: Partial<{ [K in Actions]: () => void }>,
) => void) => {
  return (event, callbacks): void => {
    if (isActive && !isActive(event)) {
      return;
    }

    actions.forEach(({ action, keys }) => {
      if (keys.includes(event.keyCode)) {
        callbacks[action]?.();
      }
    });
  };
};
